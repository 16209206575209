import { Link } from "react-router-dom"
import { hvnUriRoot } from "../core/defines";

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップ", nameEn: "TOP", path: "/home", blank: false, img: ""},
                        {nameJp: "出勤", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: hvnUriRoot + "/system", blank: true, img: ""},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
                        {nameJp: "写メ日記",     nameEn: "DIARY", path: hvnUriRoot + "/diarylist", blank: true, img: "/img/ico/i-diary.png"},
                        {nameJp: "口コミ",       nameEn: "REVIEWS", path: hvnUriRoot + "/reviews", blank: true, img: "/img/ico/i-review.png"},
                        {nameJp: "ネット予約",   nameEn: "RESERVE", path: hvnUriRoot + "/A6ShopReservation", blank: true, img: "/img/ico/i-reserve.png"},
                        {nameJp: "女子求人",     nameEn: "女子求人", path: "https://www.girlsheaven-job.net/kumamoto/ma-195/sa-324/marimburu_kumamoto/?of=y", blank: true, img: "/img/ico/i-rec-women.png"},
                        {nameJp: "男子求人",     nameEn: "男子求人", path: "https://mensheaven.jp/10/marimburu_kumamoto/?of=y", blank: true, img: "/img/ico/i-rec-men.png"},                
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    
                    {NAV_TOP_PC.map((el)=>{
                        if(el.blank===false){
                            return(<li key={el.nameEn}><Link to={el.path}>{el.nameJp}<span>{el.nameEn}</span></Link></li>); }
                        else{ return(<li key={el.nameEn}><a href={el.path}
                                            target="_blank" rel="noreferrer noopner">{el.nameJp}<span>{el.nameEn}</span></a></li>); }
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;